var config = {
  // Set the Demo Version
  demo: false,

  // googleAddsense
  googleAddsense: "ca-pub-7883533317936955",

  //SEO Configurations
  appName: "Play Trivia",
  metaDescription: "Challenge your friends to play trivia.",
  metaKeyWords: "trivia,quiz,online,play trivia,multiplayer",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://admin.playtrivia.com/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 2, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 4, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "us", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 5,
  randomBattleSeconds:10, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 0, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 0, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations
  apiKey: "AIzaSyCq_k-rKC_L99-NwTaEJOa5vM4DprYhc_s",
  authDomain: "playtrivia-ddb71.firebaseapp.com",
  projectId: "playtrivia-ddb71",
  storageBucket: "playtrivia-ddb71.appspot.com",
  messagingSenderId: "537201423460",
  appId: "1:537201423460:web:99deca78860b0030271b71",
  measurementId: "G-G3WKXR5GK8",

  //footer area
  companytext: "Challenge your friends for this online trivia game.",
  addresstext: "",
  phonenumber: "",
  email: "support @playtrivia.com",
  facebooklink: "https://www.facebook.com/playtrivia.com",
  instagramlink: "https://www.instagram.com/playtrivia.com",
  linkedinlink: "https://www.linkedin.com/company/PlayTrivia",
  weblink: "https://playtrivia.com/",
  companyname: "PlayTrivia.com",
};

export default config;
